<template>
  <div class="recommendations wrap">
    <div v-text="title" class="text mb-5"/>
    <div class="recommendations__body">
      <div
        v-for="(recommendation, i) in recommendations"
        :key="i"
        :class="['recommendations__body--item', { reverse: i % 2 === 0 }]"
      >
        <div>
          <div v-html="recommendation.text"/>
        </div>
        <base-icon :name="icons[recommendation.section.section]" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Recommendations',
  computed: {
    colors() {
      return {
        VERY_GOOD: 'rgb(125, 251, 90)',
        GOOD: 'rgb(120, 166, 90)',
        NORMAL: 'rgb(183, 183, 183)',
        BAD: 'rgb(209, 109, 106)',
        VERY_BAD: 'rgb(234, 51, 35)',
      };
    },
    icons() {
      return {
        FO: 'food-icon',
        HO: 'house',
        PT: 'transport',
        CA: 'car',
        TR: 'travel',
      };
    },
    recommendations() {
      return this.$store.state.recommendations;
    },
    title() {
      return `На круговой диаграмме представлены ваши результаты по категориям.
        Ниже вы можете просмотреть рекомендации на основе ваших результатов
        по каждой из пройденных глав  калькулятора.`;
    },
  },
};
</script>

<style lang="sass">
  .recommendations
    width: 100%
    display: flex
    flex-direction: column
    height: auto
    backdrop-filter: blur(20px)
    color: darkgreen
    .BaseIcon
      height: 100%
      padding: 0 15px 0 15px
    &.wrap
      padding-top: 0
    &__body
      &--item
        display: flex
        margin-bottom: 25px
      .reverse
        flex-direction: row-reverse
        justify-content: flex-end
      .v-icon
        margin: 20px
</style>
