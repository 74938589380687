<template>
  <div class="total" ref="total">
    <div class="total__body wrap">
      <apex-chart
        ref="chart"
        class="total__body--chart"
        :options="chartOptions"
        :series="series"
        :width="size.width"
        height="auto"
      />
      <h1 class="total__body--text" v-text="totalEmission" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Total',
  data: () => ({
    size: {
      height: '0px',
      width: '0px',
    },
  }),
  computed: {
    totalEmission() {
      return `Углеродный след составил ${this.total?.total || 0} тонн CO2`;
    },
    total() {
      return this.$store.state.total;
    },
    series() {
      if (this.total) {
        return [
          this.total.food.total || 0,
          this.total.house.total || 0,
          this.total.public_transport.total || 0,
          this.total.car.total || 0,
          this.total.travel.total || 0,
        ];
      }
      return [0.1, 0.1, 0.1, 0.1, 0.1];
    },
    chartOptions() {
      return {
        chart: {
          type: 'pie',
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
              offset: 0,
              minAngleToShowLabel: 5,
            },
          },
        },
        legend: {
          position: 'right',
          fontSize: '18px',
          itemMargin: {
            horizontal: 5,
            vertical: 13,
          },
          offsetY: 50,
          horizontalAlign: 'left',
          formatter: (seriesName, opts) => [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]],
        },
        labels: ['Пища', 'Жилье', 'Логистика', 'Транспорт', 'Путешествия'],
        responsive: [
          {
            breakpoint: 640,
            options: {
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.$nextTick(() => {
        const delta = this.$vuetify.breakpoint.mobile ? 0 : 300;
        if (this.$refs.total) {
          const body = this.$refs.total.querySelector('.total__body');
          this.size = {
            height: `${body.clientHeight - delta}px`,
            width: `${body.clientWidth - delta}px`,
          };
        }
      });
    },
  },
};
</script>

<style lang="sass">
  .total
    width: 100%
    &__body
      display: flex
      flex-direction: column
      align-items: start
      height: 100%
      width: 100%
      &--chart
        margin-bottom: 40px
      > div
        width: 100%
</style>
