<template>
  <div class="result"  style="background-image: url('./images/back.svg')">
    <div class="result__title">
      <span v-text="'Результаты'"/>
    </div>
    <div class="result__body">
      <total />
      <recommendations />
      <div class="result__body__actions">
        <carbon-button
          text="На главную"
          @click="push('/')"
          :vue-bind="{ color: '#4CAF50' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CarbonButton from '@/common/components/CarbonButton';
import Total from '@/components/result/Total';
import Recommendations from '@/components/result/Recommendations';

export default {
  name: 'Result',
  components: {
    Recommendations,
    CarbonButton,
    Total,
  },
  data: () => ({
  }),
  methods: {
    push(path) {
      window.open(path, '_self');
    },
  },
};
</script>

<style lang="sass">
  .result
    background-size: cover
    display: flex
    flex-direction: column
    align-items: center
    color: #4caf50
    height: auto
    &__title
      font-size: 45px
      font-weight: bold
      margin-bottom: 30px
    &__body
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
      height: auto
      margin-bottom: 25px
      .total
        margin-bottom: 10px
</style>
